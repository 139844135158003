import React, { useMemo } from "react";
import {
  IApprovalItem,
  IApprovalItemRevision,
  IApprovalItemRevisionAirStatusEnum,
} from "../services/thumbz-base-api";
import { RenderImgPreview } from "./render-img-preview";
import { Box, Card, useMediaQuery, useTheme } from "@mui/material";
import { useAppSelector } from "../hooks";
import { approvalSlice } from "../features/approvals/approvalsSlice";
import IconOverlay, { IStatusIcon } from "./icon-overlay";

interface IApprovalItemPreviewProps {
  revision: IApprovalItemRevision;
  view?: "card" | "img";
  onClick?: () => void;
}

export const ApprovalItemPreview: React.FC<IApprovalItemPreviewProps> = ({
  revision,
  view = "img",
  onClick,
}) => {
  const theme = useTheme();
  const isMobilePhone = useMediaQuery(theme.breakpoints.down("sm"));
  const isRevisionSelected = useAppSelector(
    approvalSlice.selectors.isRevisionSelected
  )(revision.air_id);
  const selectorItemStatus = useAppSelector(
    approvalSlice.selectors.selectItemStatus
  )(revision.air_id);
  const icon = useIcon(selectorItemStatus);
  const isDisabled = useAppSelector((state) =>
    approvalSlice.selectors.isItemDisabled(state)(revision.air_id)
  );

  const ImgPreview = () => (
    <>
      {revision.approvalitem.approvalItemAsset[0] && (
        <RenderImgPreview
          height={"100%"}
          approvalItemAsset={revision.approvalitem.approvalItemAsset[0]}
        />
      )}
    </>
  );

  const WrapperIconOverlay = ({
    children,
    size,
  }: {
    children: React.ReactNode;
    size: number;
  }) => (
    <IconOverlay icon={icon} size={size}>
      {children}
    </IconOverlay>
  );

  if (isMobilePhone) {
    return (
      <WrapperIconOverlay size={50}>
        <ImgPreview />
      </WrapperIconOverlay>
    );
  }

  return (
    <WrapperIconOverlay size={100}>
      <Card
        onClick={isDisabled ? undefined : onClick} // Evita o clique se o item estiver desabilitado
        sx={{
          cursor: isDisabled ? "default" : "pointer",
          borderRadius: isRevisionSelected ? 1 : 0,
          border: isRevisionSelected
            ? `4px solid ${theme.palette.primary.main}`
            : "1px solid transparent", // Usa a cor principal do tema
          transform: isRevisionSelected ? "scale(1.05)" : "scale(1)", // Animação de "scale"
          transition: "transform 0.2s ease-out", // Transição suave
          boxShadow: isRevisionSelected ? theme.shadows[6] : theme.shadows[1], // Aumenta o shadow se selecionado
          position: "relative", // Para posicionar o overlay
          pointerEvents: isDisabled ? "none" : "auto", // Desativa interações se desabilitado
        }}
        key={revision.air_id}
        elevation={isRevisionSelected ? 4 : 0}
      >
        {/* Imagem principal */}
        <ImgPreview />

        {/* Overlay com opacidade */}
        {isDisabled && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.5)", // Cor e opacidade do overlay
              zIndex: 1, // Coloca o overlay acima do ImgPreview
            }}
          />
        )}
      </Card>
    </WrapperIconOverlay>
  );
};

export const useIcon = (status: {
  is_approved: boolean;
  is_reproved: boolean;
  is_pending: boolean;
}): IStatusIcon => {
  const icon: IStatusIcon = useMemo(() => {
    if (status.is_approved) return "success";
    if (status.is_reproved) return "error";
    if (status.is_pending) return "warning";
    return "warning";
  }, [status]);

  return icon;
};
