import { Save } from "@mui/icons-material";
import { CircularProgress, Fab } from "@mui/material";
import React from "react";
import {
  approvalSlice,
  finishApproval,
} from "../features/approvals/approvalsSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { IApprovalStageCustomer } from "../services/thumbz-base-api";
import Swal from "sweetalert2";

interface IFinishProps {}

export const Finish: React.FC<IFinishProps> = () => {
  const dispatch = useAppDispatch();
  const { loading, approvalStageCustomer, error } = useAppSelector(
    (state) => state.approval
  );
  const { isAllRevised } = useAppSelector(approvalSlice.selectors.selectCount);

  function handleFinishApproval() {
    const data: IApprovalStageCustomer | null = approvalStageCustomer;

    if (!data) {
      Swal.fire({
        title: "Erro",
        text: "Não foi possível finalizar a aprovação.",
        icon: "error",
      });
      return; // Add return to prevent further execution
    }

    Swal.fire({
      title: "Finalizar aprovação?",
      text: "Não será possível alterar a aprovação após finalizar.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, finalizar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(finishApproval(data));
      }
    });
  }

  if (isAllRevised) {
    return (
      <Fab
        variant="extended"
        size="medium"
        color="primary"
        onClick={handleFinishApproval}
        disabled={loading}
        sx={{
          position: "fixed",
          bottom: 30,
          left: "50%",
          transform: "translateX(-50%)",
          whiteSpace: "nowrap", // Garantir que o texto não quebre
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 3, // Ajuste de padding para manter o tamanho consistente
        }}
      >
        {!loading ? (
          <Save sx={{ mr: 1 }} />
        ) : (
          <CircularProgress
            color="secondary"
            size={18}
            style={{ marginRight: 8 }}
          />
        )}
        {loading ? "Aguarde..." : "Finalizar aprovação"}
      </Fab>
    );
  }

  return null;
};
